import {
  FunctionComponent,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { useLocalStorage, useProfile, useDialog } from "../../../shared/hooks";
import { MainClientLayout } from "../../../shared/layouts";
import { useAuthenticated } from "../../../shared/hooks";
import { sendTagEvent } from "utils/analytics";
import { format } from "date-fns";

import PrincipalPanel from "./components/PrincipalPanel";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { DIALOGS } from "shared/constants/localStorage";
import { getLastMortgage } from "services/api/mortgages.service";
import dataStorageService from "services/api/dataStorage.service";
import useMortgage from "shared/hooks/useMortgage";
import { Bid } from "shared/types/Bid";
import {
  getListOfBidsinMortgageId,
} from "../../../services/api";
import { MORTGAGE_FORM_SERVICE_FEEDBACK } from "shared/constants/localStorage";
import { toast } from "react-toastify";
import Zendesk from "react-zendesk";
import posthog, { Survey } from "posthog-js";
import { ClientProfile } from "shared/types/Profile";
import PPOffers from "./components/PrincipalPanel/components/PPOffers/indext";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AlertOfferts from "./components/PrincipalPanel/components/AlertOfferts";
import { asignarColor, generarCadenaAleatoria } from "utils/helpers";
import { NotResults } from "shared/components";

declare global {
  interface Window {
    hbspt: any;
  }
}

interface AlertProps {
  bids: Bid[];
  id?: string;
  status?: string;
  profile: ClientProfile;
}

const discardMStatusMsg: Partial<
  Record<MortgageRes["status"], SweetAlertOptions["text"]>
> = {
  expired:
    "Esta solicitud ha expirado. Si has aceptado una oferta y tienes alguna consulta acerca de la documentación o quieres hablar con uno de nuestros agentes, puedes contactar con nosotros.",
  collapsed:
    "Sentimos mucho que no haya sido tu match perfecto 😔 sin embargo siempre hay segundas oportunidades, puedes reintentar la subasta para buscar tu siguiente match.",
};

const Home: FunctionComponent<{}> = (): ReactComponentElement<any> => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role] = useLocalStorage("role");
  const profile = useProfile<ClientProfile>().profile;
  const [mortgage, setMortgage] = useState<MortgageRes>();
  const { getBids } = useMortgage(mortgage?.id);
  const { REACT_APP_ZENDESK_KEY }: any = process.env;
  const [offertStatus, setOffertStatus] = useState<Bid["status"] | string>("");

  useAuthenticated((allow) => {
    if (!allow) navigate("/sign-in" + window.location.search);
  }, "client");

  useEffect(() => {
    if (profile) {
      sendTagEvent({
        event: "page_view",
        page_category: location.search,
        page_location: location.pathname, // URL
        user_type: role,
        user_id: profile.id,
        user_registration_date: format(new Date(), "MM/dd/yyyy"),
        user_login_status: "active",
      });
    }
  }, [profile]);

  useEffect(() => {
    getMortage();
  }, []);

  const getMortage = (status?: string) => {
    getLastMortgage()
      .then((res) => {
        const lastMortgage = res.data.results;

        const mStatus = lastMortgage.status;

        if (lastMortgage.bids.length > 0) {
          const bidStatus = status ? status : getStatusBid(lastMortgage.bids);
          const isPending = bidStatus === "pending" ? "entity" : undefined;

          // getAcceptedOffers(lastMortgage, bidStatus as Bid["status"]);
          getOfferts(lastMortgage, bidStatus, isPending);
        } else {
          setMortgage(lastMortgage);
        }

        if (mStatus === "paid") {
          //por mientras un booleano en localstorage de si vio o no el popup
          const isBidPaidDialogSeen = localStorage.getItem(
            DIALOGS.BID_PAID_DIALOG_SEEN,
          );

          navigate("/client/mortgage/create?step=0");
          return;
        }
        if (mStatus === "created" || mStatus === "draft") {
          navigate("/client/mortgages");
          return;
        }
        if (mStatus === "closed") {
          hasCloseMortgageDialog();
          return;
        }

        if (mStatus === "collapsed" || mStatus === "expired") {
          Swal.fire({
            icon: "info",
            title: "Información",
            text: discardMStatusMsg[mStatus],
            showCancelButton: mStatus === "expired",
            cancelButtonText: "Contactar",
            confirmButtonText: "Aceptar",
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
              // User clicked on "Contactar" button
              window.open("https://link.colibid.com/contacto", "_blank");
            }
            navigate("/client/mortgage/create?step=0");
          });
        }
        if (mStatus === "active") {
          /*  getAcceptedOffers();
          if (modeAccepted) {
            getAcceptedOffers(lastMortgage);
          } else {
            getOfferts(lastMortgage, "pending", "entity");
          } */

          return;
        }
        if (
          mStatus === "closed_successfully" ||
          mStatus === "in_verification_accepted"
        ) {
          /*  if (modeAccepted) {
            getAcceptedOffers(lastMortgage);
          } else {
            getOfferts(lastMortgage, "pending", "entity");
          }
          return; */
        }
      })
      .catch((e) => navigate("/client/mortgage/create?step=0"));
  };

  const getStatusBid = (bids: Array<Bid>) => {
    return bids.some((bid) => bid.status === "accepted")
      ? "accepted"
      : bids.some((bid) => bid.status === "pending")
      ? "pending"
      : bids.some((bid) => bid.status === "rejected")
      ? "rejected"
      : bids.some((bid) => bid.status === "closed")
      ? "closed"
      : "closed";
  };

  const getOfferts = (
    mortgage: MortgageRes,
    status: string,
    group?: string
  ) => {
    getListOfBidsinMortgageId(mortgage.id, status, group).then((res) => {
      let bids = res.data.results.length;

      if (group !== undefined && bids) {
        let list = res.data.results.map((item: any) => {
          let entity = item.hash;
          let bids = item.content.map((bid: any) => {
            bid.entity = entity;
            return bid;
          });

          return [...bids];
        });
        const resultado = [].concat(...list);

        mortgage.bids = asignarColor(resultado);
      } else {
        mortgage.bids = res.data.results;
      }

      setMortgage(mortgage);
    });
  };

  const getAcceptedOffers = (mortgage: MortgageRes, status: Bid["status"]) => {
    getListOfBidsinMortgageId(mortgage.id, status).then((res) => {
      const bids = res.data.results;
      if (bids) {
        mortgage.bids = [];
        mortgage.bids = bids;
        setMortgage(mortgage);
      }
    });
  };

  const hasCloseMortgageDialog = () => {
    const is_confirm = localStorage.getItem("confirm_closed_mortgages");
    if (!is_confirm || is_confirm === "null") {
      Swal.fire({
        title: "La subasta ha terminado. \r\n No recibirás nuevas pujas.",
        html: `<br><b>Las ofertas que has recibido caducarán en 3 días.</b> Te recomendamos que elijas una lo antes posible.<br><br>Recuerda que en caso de que tu oferta sea de un asesor solo deberás pagar cuando hayas firmado tu hipoteca, y que nuestro servicio es totalmente gratuito y <b>sin compromiso</b>. `,
        icon: "info",
        showCancelButton: false,
        confirmButtonText: "Aceptar",
      }).then((result) => {
        localStorage.setItem("confirm_closed_mortgages", "1");
      });
    }
  };

  return (
    <MainClientLayout>
      <Container maxWidth="xl">
        {/* {profile && !profile.can_create_mortgage && <PrincipalPanel />} */}

        {/* {bidsOffers.length === 0 && (
          <NotResults
            title="No tienes ofertas disponibles"
            caption="ten paciencia!, llegan pronto"
          />
        )} */}
        <PrincipalPanel
          m={mortgage}
          onUpdateData={(data) => {
            getMortage(data);
          }}
        />
        {profile && (
          <AlertDialog
            bids={getBids()}
            id={mortgage?.id}
            status={mortgage?.status}
            profile={profile}
          />

        )}
        {role === "client" &&
        (mortgage?.status === "closed" || "in_verification_accepted") ? (
          <Zendesk defer zendeskKey={REACT_APP_ZENDESK_KEY} />
        ) : null}
      </Container>
    </MainClientLayout>
  );
};

export default Home;

const AlertDialog = ({ bids, id, status, profile }: AlertProps) => {
  const { isShowing, closeDialog, openDialog } = useDialog();
  const [trustpilotSession, setTrustpilotSession] = useState("");
  const [token] = useLocalStorage("access_token");
  const [searchParams, setSearchParams] = useSearchParams();
  const [surveyForm, setSurveyForm] = useState<any>({
    id: "",
    name: "",
    title: "",
    question: "",
    selector: "",
    response: "",
    appearance: null,
  });
  const setStorageService = () => {
    dataStorageService.setData({
      key: MORTGAGE_FORM_SERVICE_FEEDBACK,
      payload: {
        id,
      },
    });
    closeDialog();
  };

  useEffect(() => {
    // this effect is to check if is set the query param tp_status=true
    // it is only available when a customer finish the Trustpilot form and is redirected to the platform
    const trustpilotStatus = searchParams.get("tp_status");
    if (trustpilotStatus) {
      toast.success(
        "¡Muchas gracias por compartir tu opinión con nosotros nos ayudas a seguir creciendo! 🤗",
      );
    }
  }, [searchParams]);

  // Trustpilot deactivation
  // useEffect(() => {
  //   if (!profile?.firstname) return;
  //   createTrustpilotSession({ access_token: token })
  //     .then((response: any) => {
  //       setTrustpilotSession(response.data.results.url);
  //     })
  //     .catch((e: any) => {
  //       toast.error(
  //         "Lo sentimos ha ocurrido un error inesperado, por favor inténtalo más tarde 😔, error(Hufcts)"
  //       );
  //       console.log(e);
  //     });
  // }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      console.log({ posthog, profile });
      posthog.getSurveys((surveys) => {
        if (surveys?.length > 0) {
          const survey: any = surveys.find(
            (survey) => survey.name === "FormFeedback",
          );

          if (survey) {
            const { question } = survey.questions[0];
            setSurveyForm({
              id: survey.id,
              name: survey.name,
              title: survey.name,
              question: question,
              appearance: survey.appearance,
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (bids.length >= 2 && (status === "active" || status === "closed")) {
      dataStorageService
        .getData(MORTGAGE_FORM_SERVICE_FEEDBACK)
        .catch(() => {
          Swal.fire({
            title: "¿Te está gustando el servicio de Colibid?",
            text: "Tu opinión es muy importante para nosotros",
            padding: "1rem",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: "No",
            denyButtonColor: "#999999",
          }).then((res) => {
            // doesnt matter with btn you click i'll always set the cache
            setStorageService();
            if (res.isConfirmed) {
              const linkToOpen = "https://g.page/r/Cbbdt47SICZzEAI/review";
              window.open(linkToOpen, "_blank");
            } else if (res.isDenied) {
              openDialog();
            }
          });
        });
    }
  }, [bids.length, trustpilotSession]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Aquí puedes manejar la presentación del formulario

    posthog.capture("survey sent", {
      $survey_id: surveyForm.id, // required
      $survey_response: surveyForm.response, // required
      $survey_name: surveyForm.name, // optional
    });
    toast.success(
      "¡Muchas gracias por compartir tu opinión con nosotros nos ayudas a seguir creciendo! 🤗",
    );
    setStorageService();
  };

  return (
    <div>
      <Dialog
        open={isShowing}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          Tu opinión es muy importante
        </DialogTitle>

        <DialogContent>
          {/* <div id="hubspotForm"></div> */}
          <div id="#formFedback">
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                maxWidth: 345,
                margin: "auto",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography variant="h6" textAlign="center" component="div">
                {surveyForm.question}
              </Typography>
              <TextField
                id="outlined-multiline-static"
                label=""
                multiline
                rows={4}
                value={surveyForm.response}
                onChange={(e) => {
                  surveyForm.response = e.target.value;

                  setSurveyForm({ ...surveyForm });
                }}
              />
              <Button variant="contained" type="submit">
                Enviar
              </Button>
            </Box>
          </div>
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};
