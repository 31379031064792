import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Divider, Grid, Typography } from "@mui/material";
import jwt_decode from "jwt-decode";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";

import Swal from "sweetalert2";

import {
  FieldPath,
  FormProvider,
  UnpackNestedValue,
  useForm,
  useWatch,
} from "react-hook-form";
import FormFields from "shared/components/FormFields";

import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { format, parseISO } from "date-fns";
import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sendOptCodeEmail, signUp } from "services/api";
import { SignUpAdditional } from "services/api/auth.service";
import {
  USER_AGE_RANGE,
  WARNING_CLIENT_AGE,
} from "shared/constants/mortgageForm";
import { useLocalStorage } from "shared/hooks";
import { MortgageFormData } from "shared/types/postMortgageData";
import { getAgeByBirth } from "utils";
import { getCookie } from "utils/analytics";
import { sendTagEvent } from "utils/analytics";
import { typeSpanishDNI } from "utils/helpers";
import logger from "utils/logger";
import useMortgageFormContext from "../../hooks/useMortgageFormContext";
import {
  getUserFormName,
  isUserPensionistById,
  isUserUnemployedById,
} from "../../utils";
import MortgageCalculatorDialog from "../MortgageCalculatorDialog";
import { renderUserInputs } from "./renderInputs";
import { stepOneValidationSchema } from "./validation.schema";

export interface IStepOneProps {
  onNext?: (formData: any) => void;
  editMode?: boolean;
  defaultValues: MortgageFormData;
}

/**
 * this comps is deprecated all the steps from 1 to ..
 * @deprecated
 */
export const StepOne: FunctionComponent<IStepOneProps> = ({
  onNext,
  editMode,
  defaultValues,
}) => {
  // const { mortgageFormValue } = useMortgageForm();
  const [searchParams] = useSearchParams();
  const { onStepClick, isUserLogin } = useMortgageFormContext();
  const [role] = useLocalStorage("role");
  const isAgent = role === "realstate";
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
    resolver: yupResolver(stepOneValidationSchema),
  });
  const { control, getValues, handleSubmit, setValue, reset } = methods;

  const mortgageMode = getValues("mortgage.mode");
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const [
    solicitorType,
    userWork,
    user2Work,
    userBirthDate,
    userBirthDate2,
    userWorkDate,
    user2WorkDate,
  ] = useWatch({
    control,
    name: [
      "solicitor_type",
      "user.work",
      "user_2.work",
      "user.birthdate",
      "user_2.birthdate",
      "user.work_date",
      "user_2.work_date",
      // "user.phone",
    ],
  });

  const [disableNext, setDisableNext] = useState(false);
  const [hasUnemployConjuntoMsgShow, setHasUnemployConjuntoMsgShow] =
    useState(false);
  const [clientAges, setClientAges] = useState({
    clientAge: 0,
    clientAge2: 0,
  });

  useEffect(() => {
    if (userBirthDate) {
      const clientAge = getAgeByBirth(userBirthDate);
      setClientAges((prevAges) => ({ ...prevAges, clientAge }));
    }
    if (userBirthDate2) {
      const clientAge2 = getAgeByBirth(userBirthDate2);
      setClientAges((prevAges) => ({ ...prevAges, clientAge2 }));
    }
  }, [userBirthDate, userBirthDate2]);

  const currentYear = new Date().getFullYear();

  const MaxClientAgeAlert = useCallback((age: number, dataPeriod: number) => {
    Swal.fire({
      title: "Importante",
      html: `La edad máxima que puede tener al final del plazo de la hipoteca es de 80 años.<br/> Sin embargo, si la hipoteca termina después de cumplir los 75 años, recibirá muchas menos ofertas.<br/> La mayoría de los bancos prefieren que las hipotecas terminen antes de cumplir los 70 años, con la duración de hipoteca mínima (${dataPeriod} años) <b>terminaría a tus ${
        age + dataPeriod
      } años</b> de edad`,
      icon: "warning",
      confirmButtonText: "Aceptar",
    });
  }, []);

  useEffect(() => {
    if (clientAges.clientAge >= WARNING_CLIENT_AGE) {
      MaxClientAgeAlert(clientAges.clientAge, Number(getValues("data.period")));
    }
    if (clientAges.clientAge >= USER_AGE_RANGE.max) {
      if (solicitorType === "1") {
        setDisableNext(true);
        return;
      }
      if (solicitorType === "2" && clientAges.clientAge >= USER_AGE_RANGE.max) {
        setDisableNext(true);
      }
      return;
    }
    setDisableNext(false);
  }, [clientAges.clientAge]);

  useEffect(() => {
    if (clientAges.clientAge2 >= WARNING_CLIENT_AGE) {
      MaxClientAgeAlert(
        clientAges.clientAge2,
        Number(getValues("data.period"))
      );
      if (clientAges.clientAge >= USER_AGE_RANGE.max) {
        setDisableNext(true);
        return;
      }
    }
    setDisableNext(false);
  }, [clientAges.clientAge2]);

  const setUserInitMonth = (user: FieldPath<MortgageFormData>, date: Date) => {
    const month = date?.toLocaleDateString("es-ES", {
      month: "2-digit",
    });
    setValue(user, month);
  };
  const setUserInitYear = (user: FieldPath<MortgageFormData>, date: Date) => {
    const year = date
      ?.toLocaleDateString("es-ES", { year: "numeric" })
      .toString();
    setValue(user, year);
  };

  useEffect(() => {
    if (userWorkDate) {
      setUserInitMonth("user.work_init_month", parseISO(userWorkDate));
      setUserInitYear("user.work_init_year", parseISO(userWorkDate));
    }
  }, [userWorkDate]);
  useEffect(() => {
    if (user2WorkDate) {
      setUserInitMonth("user_2.work_init_month", parseISO(user2WorkDate));
      setUserInitYear("user_2.work_init_year", parseISO(user2WorkDate));
    }
  }, [user2WorkDate]);

  useEffect(() => {
    if (
      (solicitorType === "1" && userWork === "3") ||
      (solicitorType === "2" && userWork === "3" && user2Work === "3")
    ) {
      setDisableNext(true);
      Swal.fire({
        title: "Importante",
        html: `Lamentablemente los bancos necesitan cierta estabilidad laboral para poder hacer ofertas hipotecarias. <br> Desde Colibid, te recomendamos que apliques nuevamente con tu solicitud de hipoteca cuando tu situación laboral cambie. Sin duda recibirás más y mejores ofertas.`,
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      return;
    }
    if (
      solicitorType === "2" &&
      !hasUnemployConjuntoMsgShow &&
      (userWork === "3" || user2Work === "3")
    ) {
      setDisableNext(false);
      setHasUnemployConjuntoMsgShow(true);
      Swal.fire({
        title: "Importante",
        html: `Ten en cuenta en donde usted como su acompañante tienen un trabajo o ingreso fijo, esto aumenta mas la posibilidad de que un banco analice mas su perfil`,
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      return;
    }
    setHasUnemployConjuntoMsgShow(false);
    setDisableNext(false);
  }, [solicitorType, userWork, user2Work]);

  const handleOnNext = (data: UnpackNestedValue<MortgageFormData>) => {
    data.user.type_dni = typeSpanishDNI(data.user.dni);
    data.user_2.type_dni = typeSpanishDNI(data.user_2.dni);
    if (!isUserLogin && data) {
      register(data);
      return;
    }
    onNext?.(data);
  };

  const register = (form: MortgageFormData) => {
    const cRef = searchParams.get("r");
    let referrerId = "";
    let refId = localStorage.getItem("ref_id") || "";
    if (cRef !== "" && cRef) {
      refId = cRef;
      referrerId = cRef;
      // setReferrerId(cRef);
      localStorage.setItem("ref_id", cRef || "");
    }
    if (refId !== "") {
      referrerId = refId;
      // setReferrerId(refId);
    }

    ReactGA.event({
      category: "Mortgage Form",
      action: "(Step 0) Entered the registration page",
      value: 0,
    });
    const additional: SignUpAdditional = {
      referral: referrerId,
    };
    const searchParamsFromCookie = new URLSearchParams(getCookie("qp"));
    searchParamsFromCookie.forEach((v, k) => {
      additional[k] = v;
    });

    const email = form.user.email;
    signUp({
      email,
      firstname: form.user.name.trim(),
      lastname: form.user.surname.trim(),
      password: "",
      phone: form.user.phone,
      additional,
      otp_code: "",
      should_login: true,
    })
      .then((response) => {
        //remove cookie to end tracking
        document.cookie = "qp=";

        var username = email;
        if (window.location.search.indexOf("r") !== -1) {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());

          if (params?.r) {
            sendTagEvent({
              event: "page_view",
              page_category: window.location.search,
              page_location: window.location.pathname, // URL
              user_type: role,
              user_registration_date: format(new Date(), "MM/dd/yyyy"),
              user_login_status: "active",
              r: params.r,
            });
          }
        }

        TagManager.dataLayer({
          dataLayer: {
            event: "app_sign_up_ok",
            method: "email",
          },
        });

        // if ("access_token" in response.data) {
        //   let role = "client";
        //   let home = "/client/mortgage/create?step=0";
        //   const payload: any = jwt_decode(response.data.access_token);

        //   if (payload.resource_access?.bidder) {
        //     role = "broker";
        //     home = "/broker/applications";
        //   }
        //   setTokenSSID(response.data.access_token);

        //   localStorage.setItem("access_token", response.data.access_token);
        //   localStorage.setItem("role", role);
        //   localStorage.setItem("name", payload.name);
        //   localStorage.setItem("email", payload.email);
        //   localStorage.setItem("username", payload.preferred_username);
        //   localStorage.setItem("email_verified", payload.email_verified);

        //   // navigate(home);
        // }

        onStepClick()(form);
        sendOptCodeEmail(email);
        toast.success("Registro exitoso. ¡Bienvenido a Colibid! 😄");
        // signIn({ username, password: "" }).then((response: any) => {});
      })
      .catch((e: any) => {
        let message = e?.message;
        if (e.response && e.response?.status === 409) {
          message = "Este correo electrónico ya se encuentra registrado";
        } else if (e.response && e.response?.status === 406) {
          message = "Código de validación inválido";
        } else {
          message =
            "Lo sentimos ha ocurrido un error inesperado al solicitar la información, por favor inténtalo más tarde 😔";
        }
        toast.error(message + "" + e.message);
        TagManager.dataLayer({
          dataLayer: {
            event: "app_sign_up_error",
            error_message: message,
          },
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(handleOnNext, (e) => {
          logger.info(getValues());
          logger.info(e);
        })}
      >
        {[1].includes(mortgageMode) && role !== "realstate" && (
          <MortgageCalculatorDialog />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Registro</Typography>
            <Divider component="br" />
            <Typography variant="body1">Cuéntanos un poco más de ti</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormFields.RadioInput
              disabled={editMode}
              row
              name="solicitor_type"
              label="Tipo de Solicitud"
              radioOptions={[
                { value: "1", label: "Individual" },
                { value: "2", label: "Conjunta" },
              ]}
            />
          </Grid>

          {renderUserInputs({
            userWork,
            currentYear,
          })}

          {solicitorType === "2" && (
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    Información Persona Conjunta
                  </Typography>
                </Grid>

                {renderUserInputs({
                  userWork: user2Work,
                  currentYear,
                  isUser2: true,
                })}
              </Grid>
            </Grid>
          )}
          {onNext && (
            <Grid item xs={12} md={12}>
              <br />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={disableNext}
                endIcon={<ArrowForwardIosIcon />}
              >
                Siguiente
              </Button>
            </Grid>
          )}
          <DevTool control={methods.control} placement="top-left" />
        </Grid>
      </form>
    </FormProvider>
  );
};

export const getStepOneFormTriggerArr = ({
  solicitorType,
  clientAges: { clientAge, clientAge2 },
  userWork,
  user2Work,
}: {
  solicitorType: string | number;
  clientAges: { clientAge: number; clientAge2: number };
  userWork: MortgageFormData["user"]["work"];
  user2Work: MortgageFormData["user_2"]["work"];
}) => {
  const user: FieldPath<MortgageFormData>[] = [
    "user.name",
    "user.surname",
    "user.dni",
    "user.birthdate",
    "user.work",
    "user.work_date",
    "user.work_init_month",
    "user.work_init_year",
    "user.residence",
    "user.profession",
    "user.email",
    "user.phone",
    "user.marital_status",
    "user.children",
    "user.current_house_situation",
  ];
  const triggerSet: Set<FieldPath<MortgageFormData>> = new Set(user);
  triggerSet.add("solicitor_type");
  const isUserPensionist = isUserPensionistById(Number(userWork));
  if (isUserPensionist) {
    triggerSet.delete("user.work_init_month");
    triggerSet.delete("user.work_init_year");
    triggerSet.delete("user.work_date");
  }

  if (solicitorType === "2") {
    user.forEach((u) => {
      const user2 = getUserFormName({
        isUser2: true,
        name: u,
      }) as FieldPath<MortgageFormData>;
      triggerSet.add(user2);
    });

    //userWorks
    const isUserUnemployed = isUserUnemployedById(Number(userWork));
    const isUser2Unemployed = isUserUnemployedById(Number(user2Work));
    const isUser2Pensionist = isUserPensionistById(Number(user2Work));

    if ((isUserUnemployed && !isUser2Unemployed) || isUserPensionist) {
      triggerSet.delete("user.work_init_month");
      triggerSet.delete("user.work_init_year");
      triggerSet.delete("user.work_date");
    }
    if ((!isUserUnemployed && isUser2Unemployed) || isUser2Pensionist) {
      triggerSet.delete("user_2.work_init_month");
      triggerSet.delete("user_2.work_init_year");
      triggerSet.delete("user_2.work_date");
    }

    if (
      (clientAge < WARNING_CLIENT_AGE && clientAge2 >= WARNING_CLIENT_AGE) ||
      (clientAge >= WARNING_CLIENT_AGE && clientAge2 < WARNING_CLIENT_AGE)
    ) {
      triggerSet.delete("user.birthdate");
      triggerSet.delete("user_2.birthdate");
    }
  }

  const newTriggerArr: FieldPath<MortgageFormData>[] = Array.from(
    triggerSet.values()
  );
  return newTriggerArr;
};
