import { Button, ButtonProps, Divider, Grid, Typography } from "@mui/material";
import { MouseEventHandler, ReactNode } from "react";
import { CustomDialog, ImageButton } from "shared/components";
import { TRACE_DATA_LINK } from "shared/constants/links";
import { useDialog } from "shared/hooks";
import IMortgageSchema from "shared/schemas/mortgage.schema";
import { MortgageRes } from "shared/types/BaseApiResponse";
import BankFlipBtn from "./components/BankFlipBtn";
import TruelayerBtn from "./components/TruelayerBtn";

export type DocAssistantProps = {
  mortgage: IMortgageSchema | MortgageRes;
  children?: ReactNode;
  /**onClick para el boton manualmente */
  onClickManualmenteBtn?: () => void;
};

/**Deprecated */
export default function DocAssistant({
  mortgage,
  onClickManualmenteBtn,
}: DocAssistantProps) {
  const isConjunta = mortgage?.mortgage_data.solicitor_type === 2;
  const shouldShowBankFlipBtn =
    (!mortgage?.main_user_complete_cpin ||
      (isConjunta && !mortgage.partner_complete_cpin)) &&
    mortgage?.status === "in_verification_accepted" &&
    mortgage?.document_status === "pending";
  const shouldShowTruelayerBtn =
    !mortgage?.main_user_complete_truelayer ||
    (isConjunta && !mortgage.partner_complete_truelayer);
  return (
    <>
      <Typography variant="h6" align="center" sx={{ fontWeight: "400" }}>
        Por favor escoge una de las opciones para cargar documentación{" "}
      </Typography>
      <Divider component="br" />
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        paddingX="0.5rem"
      >
        {shouldShowBankFlipBtn && (
          <Grid>
            <BankFlipBtn mortgage={mortgage} />
          </Grid>
        )}

        {/* {mortgage && shouldShowTruelayerBtn && (
          <Grid>
            <TruelayerBtn mortgage={mortgage} />
          </Grid>
        )} */}

        <Grid>
          <ImageButton
            title="Obtén tu nota simple aquí"
            src="/assets/img/sources/add_document.svg"
            onClick={() => {
              window.open(TRACE_DATA_LINK, "_blank");
            }}
          />
        </Grid>
        {onClickManualmenteBtn && (
          <Grid>
            <ImageButton
              title="Manualmente"
              src="/assets/img/sources/Manual.svg"
              onClick={onClickManualmenteBtn}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export const DocAsstBtn = ({
  mortgage,
  ...btnProps
}: Pick<DocAssistantProps, "mortgage"> & ButtonProps) => {
  const { isShowing, openDialog, closeDialog } = useDialog();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    openDialog();
    btnProps.onClick?.(e);
  };
  return (
    <>
      <Button {...btnProps} onClick={handleClick}>
        {btnProps.children}
      </Button>
      <CustomDialog open={isShowing} onClose={closeDialog} fullWidth>
        <DocAssistant mortgage={mortgage} />
      </CustomDialog>
    </>
  );
};
