import React, { useEffect, useRef, useState } from "react";
import Router from "./shared/Router";
import OneSignal from "react-onesignal";
import { Maintenance } from "pages";
import subject, { USER_LOGGED } from "./utils/subjects";
import { Detector } from "react-detect-offline";

import "./App.scss";
import { toast } from "react-toastify";
import { createBxCookie, hasBxCookie } from "./utils/analytics";

const initalStateItWentOffline = { online: true, offline: false };

const { REACT_APP_MAINTENANCE }: any = process.env;
const { REACT_APP_CONNECTION_DETECTOR }: any = process.env;

function App() {

  // Initialize the _bx cookie (blackbox)
  if (!hasBxCookie()) {
    createBxCookie();
  }

  const [underMaintance, setUnderMaintance] = useState(false);

  const itWentOffline = useRef(initalStateItWentOffline);
  const [connectionDetector, setConnectionDetector] = useState(false);

  const [onesignalUser, setOnesignalUser] = useState<any>({
    onesignal_unique_id: "",
    onesignal_hash_id: "",
  });

  useEffect(() => {
    const under = REACT_APP_MAINTENANCE === "true" ? true : false;
    setUnderMaintance(under);

    const detector = REACT_APP_CONNECTION_DETECTOR === "true" ? true : false;
    setConnectionDetector(detector);
  }, []);

  const initOneSignal = async (user_entity: any) => {
    await OneSignal.init({
      appId: `${process.env.REACT_APP_ONESIGNAL_APP_ID}`,
      safari_web_id: process.env.REACT_APP_ONESIGNAL_APP_ID_SAFARI,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: false,
      },
      promptOptions: {
        actionMessage:
          "¿Deseas recibir notificaciones sobre el estado de tu solicitiud?",
        exampleNotificationTitleDesktop: "Esta es una notificación de ejemplo.",
        exampleNotificationMessageDesktop:
          "Las notificaciones aparecerán en su escritorio",
        exampleNotificationTitleMobile: "Esta es una notificación de ejemplo.",
        exampleNotificationMessageMobile:
          "Las notificaciones aparecerán en su dispositivo",
        exampleNotificationCaption:
          "Puede anular su suscripción en cualquier momento",
        acceptButton: "Continuar",
        acceptButtonText: "Continuar",
        cancelButton: "Cancelar",
        cancelButtonText: "Cancelar",
      },
    });

    await OneSignal.showSlidedownPrompt();
    if (user_entity.onesignal_unique_id !== "") {
      // Remove external user id only if not equal
      if (user_entity.onesignal_unique_id !== OneSignal.getExternalUserId()) {
        OneSignal.removeExternalUserId();
      }

      // Set new external user id
      OneSignal.setExternalUserId(
        user_entity.onesignal_unique_id,
        user_entity.onesignal_hash_id
      );
    }
  };

  useEffect(() => {
    const userLogged = subject
      .listen(USER_LOGGED)
      .subscribe(async ({ value }) => {
        setOnesignalUser(value.onesignal);
      });

    return () => {
      if (userLogged) {
        userLogged.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    // Init onesignal
    if (process.env.REACT_APP_ONESIGNAL_ALLOW === "true" && onesignalUser?.onesignal_unique_id) {
      initOneSignal(onesignalUser);
    }
  }, [onesignalUser]);

  return (
    <>
      {underMaintance ? (
        <>
          <Maintenance />
        </>
      ) : (
        <>
          {connectionDetector ? (
            <Detector
              render={({ online }) => {
                if (!online) {
                  itWentOffline.current = { online: false, offline: true };
                  toast.warning("Has perdido la conexión de internet", {
                    position: "bottom-center",
                    toastId: "its offline",
                  });
                }
                if (online && itWentOffline.current.offline) {
                  toast.success("Conexión restablecida", {
                    position: "bottom-center",
                    toastId: "its online",
                    onClose: () =>
                      (itWentOffline.current = initalStateItWentOffline),
                  });
                }
                return null;
              }}
            />
          ) : null}
          <Router />
        </>
      )}
    </>
  );
}

export default App;
