import React, { useEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import jwt_decode from "jwt-decode";

import { NotFound } from "./components";

import {
  SignIn,
  SignUp,
  ResetPassword,
  Referrals,
  ReferralsHowItWorks,
  ReferralLink,
  ReferralPay,
  StatusPage,
} from "../pages";
import {
  Home as ClientHome,
  CreateMortgage,
  Mortgages,
  MortgageDetails,
  ActivesMortgages,
  CompletedMortgages,
  Profile,
  MortgageCompleted,
  ManageDocuments,
  ChangePassword,
  FormSuccessMessage,
  BidDetailsPrincipalPanel,
} from "../pages/client";
import {
  Home,
  ContactForm,
  ApplyMortage,
  FormMortgage,
  Prescoring,
  Scoring,
  RealStateProfilePage,
  HowWorks,
  HowWorksColibid,
  ClientsRealState,
} from "../pages/RealState";
import { Maintenance } from "../pages";

import {
  Applications,
  Clients,
  ClientDetails,
  Bids,
  BidDetails,
  ApplicationDetails,
  AutoBidding,
  ProfileBroker,
  // Planes,
} from "../pages/broker";
import ScrollToTop from "./hooks/ScrollToTop";

import { useLocalStorage } from "../shared/hooks";
import { userProfile } from "services/api";

import { setTokenSSID } from "../utils/axios";
import FabGroup from "./components/FabGroup";
import TrueLayer from "pages/callback/TrueLayer";
import posthog from "posthog-js";
import { posthogUtils } from "utils/analytics";
import Callback from "pages/callback";
import GoCardLess from "pages/callback/GoCardLess";
import DirectPayment from "pages/callback/DirectPayment";
import ColibidData from "pages/broker/ColibidData";
import Rpay from "pages/callback/Rpay";

const Router = () => {
  const [bearer] = useLocalStorage("access_token");
  const [role] = useLocalStorage("role");
  const controller = new AbortController();

  setTokenSSID(bearer);

  useEffect(() => {
    console.log("router effecxts");
    const signInBySSID = () => {
      if (
        window.location.search.includes("ssid") // &&
        // window.location.pathname !== "/sign-in"
      ) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const ssid = urlSearchParams.get("ssid") || "";
        const payload: any = jwt_decode(ssid);

        // Prepare default values
        let role = payload.role;
        let home = "/client/home";
        // Set token for axios call's
        setTokenSSID(ssid);

        // Prepare home of user
        if (role === "broker") {
          home = "/broker/applications";
        }

        if (role === "realstate") {
          home = "/agent";
        }

        if (role === "influencer") {
          home = "/referrals";
        }

        if (role === "bank") {
          home = "/broker/applications";
        }

        // Get profile data
        userProfile(controller.signal)
          .then((response: any) => {
            const profile = response.data;
            const email = profile.user || profile.email;

            // Set info of user
            localStorage.setItem("access_token", ssid);
            localStorage.setItem("role", role);
            localStorage.setItem(
              "name",
              `${profile.firstname || profile.first_name} ${
                profile.lastname || profile.last_name
              }`,
            );
            localStorage.setItem("email", email);
            localStorage.setItem("username", email);
            localStorage.setItem("email_verified", "true");

            const distinctId = posthog.get_distinct_id();

            if (distinctId) {
              // User is already identified, perform necessary actions
              // ...
            } else {
              // User is not identified, identify the user

              posthogUtils.identifyUser({
                username: email,
                name: profile.firstname,
                email: email,
                role: role,
              });

              // ...
            }

            // Redirect to home
            window.location.href = home;
          })
          .catch((e: any) => {
            console.log(e);
          });
      }
    };

    signInBySSID();
  }, []);

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <FabGroup showBelow={250} />
      <ScrollToTop>
        <Routes>
          {role === process.env.REACT_APP_CLIENT_ROLE && (
            <Route path={"/"} element={<ClientHome />} />
          )}

          {role === process.env.REACT_APP_BROKER_ROLE && (
            <Route path={"/"} element={<Applications />} />
          )}

          <Route path={"/"} element={<SignIn />} />

          {/* Real State*/}

          <Route path={"/agent"} element={<Home />} />
          <Route path={"/agent/profile"} element={<RealStateProfilePage />} />
          <Route path={"/agent/contact"} element={<ContactForm />} />
          <Route path={"/agent/apply"} element={<ApplyMortage />} />
          <Route path={"/agent/apply/form"} element={<FormMortgage />} />
          <Route path={"/agent/howworks"} element={<HowWorks />} />
          <Route
            path={"/agent/howworks/colibid"}
            element={<HowWorksColibid />}
          />
          <Route path={"/agent/scoring"} element={<Prescoring />} />
          <Route path={"/agent/scoring/result"} element={<Scoring />} />

          <Route path={"/agent/clients"} element={<ClientsRealState />} />

          <Route path="/client/home" element={<ClientHome />} />
          <Route path="/client/mortgage/:id" element={<MortgageDetails />} />
          <Route
            path="/client/bid/:id"
            element={<BidDetailsPrincipalPanel />}
          />
          {/* {this route may be deprecated} */}
          <Route
            path="/client/mortgage/:id/completed"
            element={<MortgageCompleted />}
          />
          <Route
            path="/client/mortgages/active"
            element={<ActivesMortgages />}
          />
          <Route
            path="/client/mortgages/completed"
            element={<CompletedMortgages />}
          />
          <Route
            path="/client/mortgages/form/success/completed"
            element={<FormSuccessMessage />}
          />
          <Route path="/client/mortgage/create" element={<CreateMortgage />} />
          <Route path="/client/mortgages" element={<Mortgages />} />
          <Route path="/client/profile" element={<Profile />} />
          <Route
            path="/client/profile/documents"
            element={<ManageDocuments />}
          />
          <Route
            path="/client/profile/changepassword"
            element={<ChangePassword />}
          />
          <Route path="/broker/profile" element={<ProfileBroker />} />
          <Route path="/broker/applications" element={<Applications />} />
          <Route path="/broker/clients" element={<Clients />} />
          <Route path="/broker/clients/:id" element={<ClientDetails />} />
          <Route path="/broker/bids" element={<Bids />} />
          <Route path="/broker/bids/:id" element={<BidDetails />} />
          {/* <Route path="/broker/planes" element={<Planes />} /> */}
          <Route path="/broker/colibid-data" element={<ColibidData />} />

          <Route path="/referrals" element={<Referrals />} />
          <Route path="/referrals/info" element={<ReferralsHowItWorks />} />
          <Route path="/referrals/link" element={<ReferralLink />} />
          <Route path="/referrals/pay" element={<ReferralPay />} />
          <Route
            path="/broker/applications/:id"
            element={<ApplicationDetails />}
          />
          <Route path="/broker/auto-bidding" element={<AutoBidding />} />

          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/external-action" element={<StatusPage />} />
          <Route path="/callback" element={<Callback />}>
            <Route path="truelayer" element={<TrueLayer />} />
            <Route path="gocardless" element={<GoCardLess />} />
            <Route path="direct-payment" element={<DirectPayment />} />
            <Route path="rpay" element={<Rpay />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
