import posthog from "posthog-js";
import TagManager from "react-gtm-module";

const { REACT_APP_ENV }: any = process.env;

// Constants for blackbox cookie management
const COOKIE_NAME = "_bx";
const COOKIE_DOMAIN = REACT_APP_ENV === "production" ? ".colibid.com" : ".colibid.dev";
const COOKIE_PATH = "/";
const COOKIE_DURATION_DAYS = 30;

export interface eventTag {
  event?: string;
  method?: string;
  step_name?: string;
  page_category?: string;
  page_location?: string; // URL
  user_id?: string;
  user_type?: string;
  user_registration_date?: any;
  user_login_status?: string;
  r?: string;
}

// Send Tag Event
export function sendTagEvent({
  event,
  method,
  page_category,
  page_location,
  user_id,
  user_type,
  user_registration_date,
  user_login_status,
}: eventTag) {
  let eventObj = {
    event,
    method,
    page_category,
    page_location,
    user_id,
    user_type,
    user_registration_date,
    user_login_status,
  };
  let eveObj: any = {};
  for (const [key, value] of Object.entries(eventObj)) {
    if (value !== undefined) {
      eveObj[key] = value;
    }
  }
  TagManager.dataLayer({
    dataLayer: eveObj,
  });
}

// PostHog Utilities
interface User {
  username: string;
  email: string;
  name: string;
  role: string;
}

/**
This is an exported object named posthogUtils that contains utility functions related to user tracking using the PostHog analytics library.
**/
export const posthogUtils = {
  resetUser: () => {
    try {
      posthog.reset();
    } catch (error) {
      console.error("PostHog reset failed:", error);
    }
  },

  identifyUser: (user: User) => {
    try {
      const { username, email, name, role } = user;

      posthog.identify(email, {
        email,
        name,
        role,
      });
    } catch (error) {
      console.error("PostHog identification failed:", error);
    }
  },

  captureEvent: (eventName: string, properties: object) => {
    try {
      posthog.capture(eventName, properties);
    } catch (error) {
      console.error("PostHog event capture failed:", error);
    }
  },
};

/**
 * Blackbox Cookie Utilities
 * Create the _bx cookie with UTM and other metadata
 */
export function createBxCookie(): void {
  const queryParams = window.location.search;
  const referrer = document.referrer || "";
  const hostname = window.location.hostname;
  const timestamp = new Date().toISOString();
  const urlWithoutQueryParams = window.location.origin + window.location.pathname;
  const queryObj = Object.fromEntries(new URLSearchParams(queryParams));

  const data = {
    ref: referrer,
    host: hostname,
    url: urlWithoutQueryParams,
    timestamp: timestamp,
    qp: queryObj,
  };

  // Encode + store
  const cookieValue = encodeURIComponent(JSON.stringify(data));
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + COOKIE_DURATION_DAYS * 24 * 60 * 60 * 1000);

  document.cookie = `${COOKIE_NAME}=${cookieValue};path=${COOKIE_PATH};domain=${COOKIE_DOMAIN};expires=${expirationDate.toUTCString()};SameSite=None;Secure`;
}

/**
 * Retrieve a specific cookie value by name
 * @param name - The name of the cookie
 * @returns The value of the cookie or null if not found
 */
export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * Check if the _bx cookie already exists
 */
export function hasBxCookie(): boolean {
  return !!getCookie(COOKIE_NAME);
}