import SortIcon from "@mui/icons-material/Sort";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import {
  Button,
  Checkbox,
  Chip,
  ChipProps,
  Stack,
  SvgIconProps,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton
} from "@mui/material";
import _ from "lodash";
import React, { ChangeEvent, ReactNode, useState } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import {
  BonificationTable,
  CustomDialog,
  NotResults,
  RotatePhoneInfoMsg,
  WithTooltip,
} from "shared/components";
import { TableRows } from "shared/components/InfoCard";
import { useDialog } from "shared/hooks";
import { MortgageRes } from "shared/types/BaseApiResponse";
import { Bid, InterestMixtoDetail } from "shared/types/Bid";
import { censureChars, formatLocalCurrency } from "utils/helpers";
import { getBidderTypeInES } from "utils/translate";
import { BidReducerState } from "../../useBidReducer";
import BidDetailsWithTabs from "../BidDetailsWithTabs";
import styles from "./styles.module.scss";
import useBidTableReducer, { BidTableState } from "./useBidTableReducer";
import { getComissionValue } from "utils/bids";
import { Star, Close } from "@mui/icons-material";

type CustomColDef<T extends FieldValues> = {
  field?: FieldPath<T>;
  headerName?: string;
  className?: string;
  valueFormatter?: (b: T) => string;
  cellRenderer?: (b: T) => ReactNode;
  headerComponent?: () => JSX.Element;
  sortable?: boolean;
  comparator?: (b: T) => boolean;
};

interface BidTableProps {
  onSelect: (bid: string) => void;
  // pasar bid y mortgage para reutilizar
  bidsState: BidReducerState;
  isSmallScreen: boolean;
}

export default function BidTable({
  bidsState,
  onSelect,
  isSmallScreen,
}: BidTableProps) {
  const [bidTableState, bidTableDispatch] = useBidTableReducer({
    bids: bidsState.bids,
    mortgage: bidsState.mortgage,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleTooltipClick = () => {
    setIsModalVisible(true);
  };

  const renderModalDialog = () =>
    isModalVisible && (
      <Dialog
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: "10px", position: "relative" }, // Redondea bordes
        }}
      >
        {/* Botón de cierre (X) arriba a la derecha */}
        <IconButton
          onClick={() => setIsModalVisible(false)}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "gray",
          }}
        >
          <Close />
        </IconButton>

        {/* Contenido del modal */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", color: "black" }}
          >
            <p>
              <span className={styles.highlight}>¿Qué significa la estrella? ⭐</span>
            </p>
            <p>
              La estrella indica que este <span className={styles.highlight}>licitador </span>
              tiene un <span className={styles.highlight}>excelente rendimiento </span>
              cerrando acuerdos con clientes.
            </p>
            <p>¡Su experiencia y trayectoria lo destacan frente al resto! 🚀</p>
            <p>
              <span className={styles.highlight}>Recuerda:</span> Puedes revisar los
              detalles de la oferta y elegir la que más te convenga. Aprovecha la
              experiencia de estos licitadores destacados para conseguir las mejores
              condiciones.
            </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );

  if (bidsState.bids.length === 0) {
    return (
      <NotResults
        title="Sin resultados"
        caption="Aún no existen pujas"
        url="/assets/img/sources/not_result_banks.svg"
        resize={true}
      />
    );
  }
  const { mortgage, isSortAsc, sortCol, bidsTableState } = bidTableState;
  const censureClass: string =
    mortgage.status !== "closed_successfully" ? "censure" : "";

  let columnDefs: CustomColDef<BidTableState>[] = [
    {
      field: "bidder.name",
      headerName: "Banco o Bróker",
      headerComponent: () => {
        return (
          <WithTooltip
            title="Un bróker es un intermediario financiero autorizados por el banco de España y con certificación ICI. 
        Suelen cobrar comisiones por sus servicios, pero también suelen ofrecer mejores condiciones. ¡Priorizas pagar menos al principio o pagar menos en total? ¡Tú sabrás qué te conviene!"
          >
            <span>Banco o Bróker</span>
          </WithTooltip>
        );
      },

      className: "",
      cellRenderer(b) {
        // Check if the current bid is in the selectedBid array
        const isBidSelected =
          bidsState.selectedBid?.some(
            (selectedBid) => selectedBid.id === b.id
          ) || false;

        return (
          <Stack alignItems="center" gap={1}>
            {mortgage.status !== "closed_successfully" || !isBidSelected ? (
              <span className="censure">{censureChars(b.bidder.name)}</span>
            ) : (
              <span className="">{b.bidder.name}</span>
            )}
            {b.bidder.rating > 4 && <WithTooltip
              title=""
              position="right"
              icon={<Star fontSize="large" className={styles.pulseStart} sx={{ fontSize: 28 }} />}
              onClick={handleTooltipClick}
            >
              <span className="capitalize">
                ({getBidderTypeInES(b.bidder.bidder_type)}){" "}
              </span>
            </WithTooltip>}

            {b.bidder.rating < 4 && <span className="capitalize">
              ({getBidderTypeInES(b.bidder.bidder_type)}){" "}
            </span>}
            {/* Render Modal */}
            {renderModalDialog()}
          </Stack>
        );
      },
    },
    {
      field: "interest_data.mode",
      headerName: "Interés fijo/variable",
    },
    {
      field: "interest_rate",
      headerName: "Tasa de interés (TIN)",
      cellRenderer(b) {
        const isInterestMixto =
          b.interest_data.mode === "mixto" || b.interest_data.mode === "mixed";
        if (isInterestMixto && b.interest_data.interest) {
          return <MixInterestDialog mD={b.interest_data.interest} />;
        }
        const isInterestVariable = b.interest_data.mode === "variable";
        return (
          <Stack alignItems="center" gap={1}>
            <span className="capitalize">{`${b.interest_rate} %`}</span>
            {isInterestVariable && (
              <Typography variant="subtitle1" color="darkgray">
                +Euribor
              </Typography>
            )}
          </Stack>
        );
      },
    },
    {
      field: "general_fees",
      headerName: "Comisión",
      valueFormatter(bid) {
        return formatLocalCurrency(getComissionValue(bid.general_fees));
      },
    },
    {
      field: "conditions_details",
      headerName: "Require bonificación",
      cellRenderer(b) {
        const bCount = b.conditions_details.filter((e) => e.allow).length;
        const hasBonification = b.conditions_details.some((v) => v.allow);
        const bonificationLabel = hasBonification ? "Sí" : "No";
        if (hasBonification)
          return (
            <Stack
              direction="row"
              gap={2}
              justifyContent="center"
              alignItems="center"
            >
              {bonificationLabel}
              <BonificationChip
                label={bCount}
                bonifications={b.conditions_details}
              />
            </Stack>
          );
        return bonificationLabel;
      },
    },
    {
      field: "calculated.monthly",
      headerName: "Cuota mensual",
      valueFormatter(bid) {
        const val = _.get(bid, "calculated.monthly");

        //TODO: Check why is monthly null
        if (val === undefined || val === null || val === 0 || val === "") {
          return "No disponible en estos momentos";
        }

        return formatLocalCurrency(val) + "/mes";
      },
    },
    {
      field: "totalInterestValue",
      headerName: "Valor total intereses",
      valueFormatter(b) {
        const interest_val = formatLocalCurrency(b.totalInterestValue);
        //TODO: Check why is monthly null
        if (
          interest_val === undefined ||
          interest_val === null ||
          interest_val === "" ||
          isNaN(parseFloat(interest_val))
        ) {
          return "No disponible en estos momentos";
        }
        return interest_val;
      },
    },
    {
      field: "totalLoanValue",
      headerName: "Valor total préstamo",
      valueFormatter(b) {
        const loan_val = formatLocalCurrency(b.totalLoanValue);
        //TODO: Check why is monthly null
        if (
          loan_val === undefined ||
          loan_val === null ||
          loan_val === "" ||
          isNaN(parseFloat(loan_val))
        ) {
          return "No disponible en estos momentos";
        }
        return loan_val;
      },
    },
    {
      headerName: "Detalles",
      cellRenderer(b: Bid) {
        return <BidDetailsDialog b={b} m={mortgage} />;
      },
      sortable: false,
    },
    {
      headerName: "¡Elegir ofertas!",
      cellRenderer(b: Bid) {
        const isBidSelected =
          bidsState.selectedBid?.some(
            (selectedBid) => selectedBid.id === b.id
          ) || false;

        const filterSelectedBids = bidsState.bids.filter(
          (bid) => !bidsState.selectedBid?.includes(bid)
        );

        const filterSelectedBrokerBids = bidsState.selectedBid?.some(
          (bid) => bid.bidder.bidder_type === "broker"
        );

        let valuesToDisabled = filterSelectedBids?.filter(
          (bidNotSelected) => {
            return bidsState.selectedBid?.some(
              (selectedBid) => selectedBid.entity === bidNotSelected.entity
            );
          }
        );

        if (filterSelectedBrokerBids) {
          valuesToDisabled = filterSelectedBids?.filter(
            (bidNotSelected) => {
              return bidsState.selectedBid?.some(
                (selectedBid) => selectedBid.entity === bidNotSelected.entity || bidNotSelected.bidder.bidder_type == "broker"
              );
            }
          );
        }

        let isDisabled = valuesToDisabled.some((item) => item.id === b.id);

        return (
          <Checkbox
            disabled={bidsState.bidSubmitted || isDisabled}
            checked={isBidSelected} // Check if the bid is in the selectedBids array
            value={b.id}
            onChange={handleSelectBid}
          />
        );
      },
    },
  ];

  const defaultCols: CustomColDef<BidTableState> = {
    sortable: true,
  };

  columnDefs = columnDefs.map((e) => {
    return { ...defaultCols, ...e };
  });

  const handleSelectBid = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onSelect(value);
    // bidsDispatch({ type: "SELECT_BID", payload: e.target.value });
  };

  const handleSort = (key?: FieldPath<BidTableState>) => () => {
    if (key) {
      if (isSortAsc) {
        bidTableDispatch({ type: "SORT_DES", payload: key });
        return;
      }
      bidTableDispatch({ type: "SORT_ASC", payload: key });
    }
  };

  return (
    <div className={styles.tableContainer}>
      <RotatePhoneInfoMsg />
      <table className={styles.customTable} id="bid-table">
        {isSmallScreen ? (
          <tbody>
            {columnDefs.map((c, idx) => {
              // console.log(bidsState.selectedBid?.includes( (item: Bid) => item.id === c. ))
              console.log("c and idx", c, idx);
              return (
                <tr key={c.headerName}>
                  <TH
                    sortCol={sortCol}
                    isSortAsc={isSortAsc}
                    c={c}
                    handleSort={handleSort}
                  />
                  {bidsTableState.map((b) => (
                    <TD
                      style={{
                        backgroundColor:
                          idx === 0 || idx === columnDefs.length - 1
                            ? b.color
                            : undefined,
                      }}
                      key={`${c.headerName}: ${b.id}`}
                      c={c}
                      b={b}
                      className={c.className || ""}
                    />
                  ))}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <>
            <thead>
              <tr>
                {columnDefs.map((c, index) => {
                  // console.log(bidsState.selectedBid?.includes( (item: Bid) => item.id === c. ))

                  return (
                    <TH
                      id={index.toString()}
                      key={c.headerName}
                      sortCol={sortCol}
                      isSortAsc={isSortAsc}
                      c={c}
                      handleSort={handleSort}
                    />
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {bidsTableState.map((b) => {
                return (
                  <tr
                    key={b.id}
                    className={
                      bidsState &&
                        bidsState.selectedBid &&
                        bidsState.selectedBid.some(
                          (item) => item.entity === b.entity && item.id !== b.id
                        ) &&
                        bidsState.selectedBid.length > 0
                        ? styles.rowDisabled
                        : ""
                    }
                  >
                    {columnDefs.map((c, index) => (
                      <TD
                        style={{
                          backgroundColor:
                            index === 0 || index === columnDefs.length - 1
                              ? b.color
                              : undefined,
                        }}
                        key={`${c.headerName}: ${b.id}`}
                        c={c}
                        b={b}
                      />
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
}

type THProps = {
  isSortAsc: boolean;
  sortCol: FieldPath<BidTableState>;
  c: CustomColDef<BidTableState>;
  handleSort: (key?: FieldPath<BidTableState>) => () => void;
} & React.DetailedHTMLProps<
  React.TdHTMLAttributes<HTMLTableDataCellElement>,
  HTMLTableDataCellElement
>;

function TH({ isSortAsc, sortCol, c, handleSort, ...htmlAtt }: THProps) {
  // if (c.headerComponent) return <th>{c.headerComponent()}</th>;
  const label: ReactNode = c.headerComponent
    ? c.headerComponent()
    : c.headerName;
  return (
    <th {...htmlAtt} onClick={handleSort(c.field)}>
      <div>
        {label}{" "}
        {c.sortable && (
          <span>
            {c.field === sortCol ? (
              <ArrowIcon
                isUp={isSortAsc}
                className={c.field === sortCol ? "" : styles.deactive}
              />
            ) : (
              <SyncAltIcon sx={{ rotate: "90deg" }} />
            )}
          </span>
        )}
      </div>
    </th>
  );
}

function TD({
  c,
  b,
  ...htmlAtt
}: {
  c: CustomColDef<BidTableState>;
  b: BidTableState;
} & React.DetailedHTMLProps<
  React.TdHTMLAttributes<HTMLTableDataCellElement>,
  HTMLTableDataCellElement
>) {
  if (c.cellRenderer) return <td {...htmlAtt}> {c.cellRenderer(b)}</td>;

  let val: any = "";
  if (c.field) {
    val = _.get(b, c.field);
  }
  if (c.valueFormatter) {
    val = c.valueFormatter(b);
  }
  return <td {...htmlAtt}>{val}</td>;
}

function ArrowIcon({ isUp, ...restIcon }: SvgIconProps & { isUp: boolean }) {
  return isUp ? (
    <SortIcon {...restIcon} />
  ) : (
    <SortIcon
      {...restIcon}
      sx={{ ...restIcon.sx, transform: "rotateX(180deg)" }}
    />
  );
}

function BonificationChip({
  label,
  bonifications,
}: {
  label: ChipProps["label"];
  bonifications: Bid["conditions_details"];
}) {
  const { isShowing, openDialog, closeDialog } = useDialog();

  return (
    <>
      <Chip label={label} onClick={openDialog} />
      <CustomDialog
        open={isShowing}
        onClose={closeDialog}
        title="Bonificaciones"
      >
        <BonificationTable conditionsDetails={bonifications} />
      </CustomDialog>
    </>
  );
}

function BidDetailsDialog({ b, m }: { b: Bid; m: MortgageRes }) {
  const { openDialog, closeDialog, isShowing } = useDialog();
  const selectBid = [b];

  return (
    <>
      <Button
        size="small"
        onClick={openDialog}
        sx={{ color: "black", fontSize: "1.2rem" }}
      >
        Ver
      </Button>
      <CustomDialog open={isShowing} onClose={closeDialog}>
        <BidDetailsWithTabs bid={selectBid} mortgage={m} details={true} />
      </CustomDialog>
    </>
  );
}

function MixInterestDialog({ mD }: { mD: InterestMixtoDetail[] }) {
  const { openDialog, closeDialog, isShowing } = useDialog();
  return (
    <>
      <Button size="large" onClick={openDialog} sx={{ color: "black" }}>
        Ver intereses
      </Button>
      <CustomDialog
        open={isShowing}
        onClose={closeDialog}
        title="Tasas de la oferta mixta"
      >
        <Stack gap={1}>
          {mD.map((i, idx) => {
            const title = idx === 0 ? "Primer periodo" : "Segundo periodo";
            const isInterestVar = i.mode === "variable";
            return (
              <div key={i.mode}>
                <Typography variant="body1" color="primary">
                  {title}
                </Typography>
                <TableRows
                  data={{
                    "Tipo de tasa": i.mode,
                    "Tasa de interés (TIN)": `${i.rate}%${isInterestVar ? "(+Euribor)" : ""
                      }`,
                    Período: `${i.duration} años`,
                  }}
                />
              </div>
            );
          })}
        </Stack>
      </CustomDialog>
    </>
  );
}
